import { useEffect, useState } from "react";
import Products from "../../../components/Products/Products";
import classes from "../../../styles/Home.module.scss";
import Layout from "../../../components/Layout/Layout";
import ConfigHead from "src/components/ConfigHead/ConfigHead";
import EmptyItem from "src/components/Empty-Item/EmptyItem";
import Spinner from "src/components/Spinner/Spinner";
import Error from "../../../components/Error/Error";
import Button from "src/components/UI/Button/Button";
import { useNavigate, useParams } from "react-router";
import { useSelector, useDispatch } from "react-redux";
import {
    setPrimaryCategory,
    setProductCategories,
    setIsSearched,
} from "src/store/categories-slice";

import {
    getCategoryPrimary,
    getProductCategories,
    getProductsByCategory,
} from "src/allRequest";

export default function ProductsPage() {
    const [productsByCategory, setProductsByCategory] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [openError, setOpenError] = useState(true);
    const [notFound, setNotFound] = useState(false);
    const { primaryId, categoryId } = useParams();
    const navigate = useNavigate();
    const { visitorId } = useSelector(state => state.userStatus);
    const { primaryCategory, productCategories, isSearched } = useSelector(
        state => state.categories
    );
    const dispatch = useDispatch();
    const fetchProductsByCategory = async id => {
        if (id) {
            const productByCategory = await getProductsByCategory(id);

            if (productByCategory.error) {
                return {
                    error: productByCategory.error,
                };
            } else {
                setProductsByCategory(productByCategory.data);
                return {
                    data: productByCategory.data,
                };
            }
        }
    };
    useEffect(() => {
        setLoading(true);
        setProductsByCategory(null);
        setError(null);
        const promise = new Promise(async (resolve, reject) => {
            if (!primaryCategory) {
                const fetchCategoryPrimary = await getCategoryPrimary();
                if (fetchCategoryPrimary.error) {
                    setLoading(false);
                    setError(fetchCategoryPrimary.error);
                }
                dispatch(setPrimaryCategory(fetchCategoryPrimary.data));
                resolve(fetchCategoryPrimary.data);
            } else {
                resolve(primaryCategory);
            }
        });
        promise
            .then(async CategoryPrimary => {
                if (CategoryPrimary?.length > 0 && primaryId) {
                    const foundPrimary = CategoryPrimary?.find(
                        cat => cat.slug === primaryId
                    );

                    if (!foundPrimary) {
                        navigate("/not-found");
                        setNotFound(true);
                    }
                    if (productCategories[0].slug && !isSearched) {
                        dispatch(setIsSearched(false));
                        return productCategories;
                    } else {
                        const fetchProductCategories =
                            await getProductCategories(foundPrimary?.id);
                        if (fetchProductCategories.error) {
                            setLoading(false);
                            setError(fetchProductCategories.error);
                        } else {
                            dispatch(
                                setProductCategories(
                                    fetchProductCategories.data
                                )
                            );
                        }

                        return fetchProductCategories.data;
                    }
                }
            })
            .then(async productCategories => {
                if (productCategories?.length > 0 && categoryId) {
                    const foundCategory = productCategories.find(
                        cat => cat.slug === categoryId
                    );

                    if (!foundCategory) {
                        navigate("/not-found");
                        setNotFound(true);
                    }
                    fetchProductsByCategory({
                        parentId: foundCategory?.id,
                        visitorId,
                    }).then(data => {
                        setLoading(false);
                        if (data?.error) {
                            setError(data.error);
                        }
                    });
                }
            });
    }, [categoryId, primaryId]);
    let parentRoute;
    let foundItem;
    let foundCategory;
    if (primaryCategory) {
        foundItem = primaryCategory?.find(categ => categ.slug === primaryId);
    }
    if (primaryCategory && productCategories) {
        parentRoute = `/${primaryId}/${categoryId}`;
        foundCategory = productCategories?.find(cat => cat.slug === categoryId);
    }
    return (
        <>
            <ConfigHead
                title={
                    foundCategory?.slug
                        ? `منو آیلی | ${foundCategory?.title ?? ""}`
                        : `منو آیلی `
                }
                description="Generated by create next app"
            />
            <Layout
                hasMenu={true}
                productsByCategory={productsByCategory}
                categoryPrimary={primaryCategory}
                productCategories={productCategories}
                defaultPrimary={foundItem}
            >
                <div className="col-12 bg-color-primary row mx-0 px-0">
                    {loading && <Spinner />}
                    <div className={classes.breadCrumb}>
                        <h2>{foundItem?.title}</h2>
                        {foundItem && <h2>/</h2>}
                        <h2> {foundCategory?.title}</h2>
                    </div>
                    {productsByCategory?.length > 0 && !loading && (
                        <Products
                            products={productsByCategory}
                            parentRoute={parentRoute}
                            getProductsByCategory={fetchProductsByCategory}
                        />
                    )}
                    {!error &&
                        productsByCategory &&
                        productsByCategory?.length === 0 &&
                        !loading && (
                            <EmptyItem
                                content={` هنوز محصولی در "${foundCategory?.title}" ثبت نشده! `}
                            />
                        )}

                    {error && !notFound && (
                        <>
                            <Error
                                myError={error}
                                setOpenError={setOpenError}
                                openError={openError}
                            />
                            <div className={classes.notfound}>
                                <Button
                                    clicked={() => window.location.reload()}
                                >
                                    تلاش مجدد
                                </Button>
                            </div>
                        </>
                    )}
                </div>
            </Layout>
        </>
    );
}
