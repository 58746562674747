import classes from "./EmptyItem.module.scss";
const EmptyItem = ({ content }) => {
    return (
        <div className={classes.EmptyItem}>
            <h3
                className="color-danger center "
                style={{ fontFamily: "mikhak2" }}
            >
                {content}
            </h3>
            <h3
                className="color-danger center "
                style={{ fontFamily: "mikhak2" }}
                >
                لطفا دسته دیگری رو انتخاب کنید😉
            </h3>
        </div>
    );
};

export default EmptyItem;
