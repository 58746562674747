import { useSelector, useDispatch } from "react-redux";
import Backdrop from "../Backdrop/Backdrop";
import classes from "./ImageModal.module.scss";
import classNames from "classnames";
import { showImageModal } from "src/store/ui-slice";
import { imageUrl } from "src/helper/url-config";

const ImageModal = () => {
    const dispatch = useDispatch();
    const { imageContent, imageModal } = useSelector(state => state.ui);
    return (
        <div>
            <Backdrop
                showModal={imageModal}
                closeModal={() => dispatch(showImageModal())}
            />
            {imageModal && (
                <div
                    className={classNames({
                        [classes.imageContainer]: true,
                        [classes.showImage]: imageModal,
                        [classes.hideImage]: !imageModal,
                    })}
                >
                    <img src={`${imageUrl}/${imageContent}`} alt=" " />
                </div>
            )}
        </div>
    );
};

export default ImageModal;
