import classNames from "classnames";
import classes from "./CartItem.module.scss";
import { FiTrash2 } from "react-icons/fi";

import { addItemToCart, removeItemFromCart } from "../../../store/cart-slice";
import { useDispatch } from "react-redux";
import { imageUrl } from "src/helper/url-config";
const CartItem = ({ image, title, price, id, quantity }) => {
    const dispatch = useDispatch();
    const addItemHandler = () => {
        dispatch(
            addItemToCart({
                id,
                price,
                title,
                image,
            })
        );
    };
    const removeItemHandler = () => {
        dispatch(removeItemFromCart({ id }));
    };

    return (
        <div className="col-12 col-sm-8 mt-4 mx-auto">
            <div
                className={classNames({
                    "col-12  align-center justify-content-around px-0": true,
                    [classes.CartItem]: true,
                })}
            >
                <div className="col-4 col-md-5 h-100 align-center">
                    <div
                        className={classNames({
                            [classes.image]: true,
                            "mx-auto": true,
                        })}
                    >
                        <img src={`${imageUrl}/${image}`} alt="" />
                    </div>
                </div>
                <div className="col-4 align-start flex-column h-100 d-flex justify-content-center align-items-start">
                    <h5 className="color-white  px-2 "> {title}</h5>
                </div>

                <div
                    className={classNames({
                        "col-4 d-flex justify-content-around align-items-center flex-column h-100": true,
                        [classes.buyList]: true,
                    })}
                >
                    <div
                        className={classNames({
                            [classes.numberContainer]: true,
                            "col-10 col-md-8 btn-group color-light center mt-3": true,
                        })}
                    >
                        <button
                            type="button"
                            className="btn btn-white btn-sm "
                            onClick={addItemHandler}
                        >
                            +
                        </button>
                        <span
                            className={classNames({
                                " center align-center color-white": true,
                                [classes.Count]: true,
                            })}
                        >
                            {quantity}
                        </span>
                        <button
                            type="button"
                            className="btn btn-white btn-sm"
                            onClick={removeItemHandler}
                        >
                            {quantity > 1 ? "-" : <FiTrash2 />}
                        </button>
                    </div>
                    <div className={classes.PriceContainer}>
                        <h6 className="color-dark d-block mx-1">
                            {" "}
                            {price.toLocaleString(3)}
                        </h6>
                        <h6
                            className={classNames({
                                " d-block ": true,
                                [classes.price]: true,
                            })}
                        >
                            {" "}
                            تومان{" "}
                        </h6>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CartItem;
