import Layout from "src/components/Layout/Layout";
import Button from "src/components/UI/Button/Button";
import classes from "./notfound.module.scss";
import { useSelector } from "react-redux";
import ConfigHead from "src/components/ConfigHead/ConfigHead";
import { useNavigate } from "react-router";

const NotFound = () => {
    const { primaryCategory } = useSelector(state => state.categories);
    const navigate = useNavigate();
    return (
        <Layout
            hasMenu={true}
            categoryPrimary={primaryCategory ?? [{}, {}]}
            defaultPrimary={primaryCategory ? primaryCategory[0] : []}
            productCategories={[{}, {}]}
            productsByCategory={[{}, {}]}
        >
            <ConfigHead
                title="منو ایلی | صفحه پیدا نشد"
                description="Generated by create next app"
            />
            <div className={classes.notfound}>
                <Button btnType="button" clicked={() => navigate("/cafe")}>
                    بازگشت به منو
                </Button>
            </div>
        </Layout>
    );
};

export default NotFound;
