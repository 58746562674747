import { useState, useEffect } from "react";
import ConfigHead from "src/components/ConfigHead/ConfigHead";
import { getSiteSettings } from "src/allRequest";
import Error from "../../components/Error/Error";
import Spinner from "../../components/Spinner/Spinner";
import Layout from "../../components/Layout/Layout";
import AboutUs from "../../components/AboutUs/AboutUs";
import { useLocation } from "react-router";

const AboutPage = () => {
    const [loading, setLoading] = useState(false);
    const [aboutInfo, setAboutInfo] = useState(null);
    const [error, setError] = useState(null);
    const [openError, setOpenError] = useState(true);
    const { pathname } = useLocation();

    useEffect(() => {
        setLoading(true);
        setError(null);
        setLoading(true);
        const fetchAboutInfo = async () => {
            const aboutInfo = await getSiteSettings();
            return aboutInfo;
        };
        if (pathname) {
            fetchAboutInfo().then(data => {
                setLoading(false);
                if (data?.error) {
                    setError(data.error);
                }
                setAboutInfo(data?.data);
            });
        }
    }, []);
    return (
        <Layout hasMenu={false}>
            <ConfigHead
                title=" منو آیلی | درباره ما"
                description="Generated by create next app"
            />
            {loading && <Spinner />}
            {error && !loading && (
                <Error
                    myError={error}
                    setOpenError={setOpenError}
                    openError={openError}
                />
            )}
            {aboutInfo && <AboutUs information={aboutInfo} />}
        </Layout>
    );
};
export default AboutPage;
