import { createSlice } from "@reduxjs/toolkit";

const initialCategoriesState = {
    primaryCategory: null,
    productCategories: [{}, {}, {}],
    isSearched:false,
};

const categoriesSlice = createSlice({
    name: "categories",
    initialState: initialCategoriesState,
    reducers: {
        setPrimaryCategory(state, action) {
            state.primaryCategory = action.payload;
        },
        setProductCategories(state, action) {
            state.productCategories = action.payload;
        },
        setIsSearched(state, action) {
            state.isSearched = action.payload;
        },
    },
});
export const { setPrimaryCategory, setProductCategories, setIsSearched } =
    categoriesSlice.actions;
export default categoriesSlice.reducer;
