import classNames from "classnames";
import classes from "./Category.module.scss";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { useNavigate, useParams } from "react-router";
import { imageUrl } from "src/helper/url-config";
import { useEffect, useState } from "react";

const Category = ({
    name,
    image,
    showMenubar,
    changedPage,
    link,
    parentId,
}) => {
    const { categoryId } = useParams();
    const navigate = useNavigate();
    const [updatedShowMenubar, setUpDatedShowMenubar] = useState(false);
    
    useEffect(() => {
        setUpDatedShowMenubar(showMenubar);
    }, [showMenubar]);
    return (
        <div
            className={classNames({
                [classes.Category]: true,
                "col-12 mx-auto  ": true,
                [classes.changedPage]: updatedShowMenubar && !changedPage,
                [classes.animate]: updatedShowMenubar && changedPage,
                [classes.notAnimate]: !updatedShowMenubar,
            })}
        >
            <div
                className={classNames({
                    [classes.ImageContainer]: true,
                    "col-12 col-xl-8 mx-auto": true,
                    [classes.ImageActive]: parentId === categoryId && parentId,
                })}
                onClick={() => {
                    if (name && categoryId) {
                        navigate(`/${link}`);
                    }
                }}
            >
                {name && categoryId && image ? (
                    <img src={`${imageUrl}/${image}`} alt="" />
                ) : (
                    <SkeletonTheme baseColor="#e5af75">
                        <Skeleton count={3} className="w-100 h-100 m-0 p-0" />
                    </SkeletonTheme>
                )}
            </div>
            <button
                className={classNames({
                    [classes.Button]: true,
                    [classes.activeButton]: parentId === categoryId && parentId,
                    "col-10 col-xl-7": true,
                })}
                onClick={() => {
                    if (name) {
                        navigate(`/${link}`);
                    }
                }}
            >
                <span className={classes.text}>{name}</span>
            </button>
        </div>
    );
};

export default Category;
