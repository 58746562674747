import { NavLink, useNavigate } from "react-router-dom";
import classes from "./Search-Item.module.scss";
import React from "react";
import classNames from "classnames";
import { useDispatch } from "react-redux";
import { setIsSearched } from "src/store/categories-slice";
import { imageUrl } from "src/helper/url-config";

const SearchItem = ({ children, link, clicked, image, price }) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    return (
        <li
            className={classes.SearchItem}
            onClick={() => {
                navigate(link);
                dispatch(setIsSearched(true));
            }}
        >
            <div
                className={classNames({
                    [classes.imageContainer]: true,
                    " col-2 d-flex align-items-center": true,
                })}
            >
                <img src={`${imageUrl}/${image}`} alt=" " />
            </div>
            <div className="col-8 positoin-relative">
                <NavLink to={link}>
                    <p onClick={clicked}>{children}</p>
                </NavLink>
            </div>
            <div
                className={classNames({
                    [classes.PriceContainer]: true,
                    "col-2": true,
                })}
            >
                <p>{price.toLocaleString(3)}</p>
                <p>تومان</p>
            </div>
        </li>
    );
};

export default SearchItem;
