import classNames from "classnames";
import classes from "./GalleryImage.module.scss";
import { imageUrl } from "src/helper/url-config";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { useDispatch } from "react-redux";
import { showImageModal, getIamgeModal } from "src/store/ui-slice";

const GalleryImage = ({ name, image, id, smallDevice }) => {
    const dispatch = useDispatch();
    return (
        <>
            <div
                className={classNames({
                    [classes.GalleryImage]: true,
                    "col-6 col-sm-4 col-lg-3 col-xl-3 mx-auto": smallDevice,
                    "col-4": !smallDevice,
                })}
            >
                <div
                    onClick={() => {
                        dispatch(showImageModal());
                        dispatch(getIamgeModal(image));
                    }}
                    className={classNames({
                        [classes.ImageContainer]: true,
                        "col-11 mx-auto": true,
                    })}
                >
                    {name && image ? (
                        <img src={`${imageUrl}/${image}`} alt="" />
                    ) : (
                        <SkeletonTheme baseColor="#e5af75">
                            <Skeleton
                                count={3}
                                className="w-100 h-100 m-0 p-0"
                            />
                        </SkeletonTheme>
                    )}
                </div>
            </div>
        </>
    );
};

export default GalleryImage;
