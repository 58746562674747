import classes from "./MenuItem.module.scss";
import { NavLink, useLocation } from "react-router-dom";
import { Fragment } from "react";
import classNames from "classnames";
const MenuItem = ({ children, link, clicked }) => {
    const location = useLocation();
    const validPath =
        link.includes("/products") && location.pathname.includes(link);

    return (
        <Fragment>
            <li
                className={classNames({
                    [classes.MenuItem]: true,
                    [classes.active]: location.asPath === link || validPath,
                    "w-100 px-0": true,
                })}
            >
                <NavLink
                    className={a => (a.isActive ? classes.active : "")}
                    to={link}
                    onClick={clicked}
                >
                    {children}
                </NavLink>
            </li>
        </Fragment>
    );
};

export default MenuItem;
