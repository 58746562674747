import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    visitorId: null,
};
export const userStatusSlice = createSlice({
    name: "userStatus",
    initialState: initialState,
    reducers: {
        getVisitorId(state, action) {
            state.visitorId = action.payload;
        },
    },
});
export const { getVisitorId } = userStatusSlice.actions;
export default userStatusSlice.reducer;
