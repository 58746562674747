import NavbarList from "../Navbar/NavbarList";
import classes from "./Header.module.scss";
import classNames from "classnames";
import Cart from "../container/Cart/Cart";
import MenuList from "../Menu/MenuList";
import { NavLink } from "react-router-dom";
import Search from "../container/Search/Search";
import imageLogo from "src/assets/images/Logo-Original2.png";

const Header = () => {
    return (
        <header>
            <div className="d-flex d-xl-none col-12 bg-color-dark p-2 px-4">
                <div className="col-6 text-start d-flex justify-content-start">
                    <MenuList />
                </div>
                <ul className="col-6 text-white  d-flex justify-content-end px-0 m-0 ">
                    <li className={classes.logoContainer}>
                        <NavLink to="/" className={classes.Logo}>
                            <img
                                src={imageLogo}
                                alt=""
                                className={classes.Image}
                            />
                        </NavLink>
                    </li>
                </ul>
            </div>

            <div
                className={classNames({
                    "m-3 justify-content-start bg-color-primary align-items-center d-none d-xl-flex col-12 px-0 mx-0": true,
                    [classes.Header]: true,
                })}
            >
                <NavbarList />
                <Search />

                <div className="col-2 mx-auto justify-content-center mx-auto h-100 d-flex align-items-center">
                    <Cart />
                </div>
            </div>
        </header>
    );
};

export default Header;
