import { useEffect, useState } from "react";
import classes from "../../styles/Home.module.scss";
import Layout from "../../components/Layout/Layout";
import ConfigHead from "src/components/ConfigHead/ConfigHead";
import EmptyItem from "src/components/Empty-Item/EmptyItem";
import Error from "../../components/Error/Error";
import Button from "src/components/UI/Button/Button";
import Spinner from "src/components/Spinner/Spinner";
import {
    setPrimaryCategory,
    setProductCategories,
} from "src/store/categories-slice";
import { useDispatch, useSelector } from "react-redux";
import { getCategoryPrimary, getProductCategories } from "src/allRequest";
import { useNavigate, useParams } from "react-router";

export default function HomePage() {
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true);
    const [openError, setOpenError] = useState(true);
    const [notFound, setNotFound] = useState(false);
    const { primaryId } = useParams();
    const navigate = useNavigate();
    const { primaryCategory, productCategories } = useSelector(
        state => state.categories
    );
    const dispatch = useDispatch();
    useEffect(() => {
        setLoading(true);
        dispatch(setProductCategories([{}, {}, {}]));
        setError(null);
        const promise = new Promise(async (resolve, reject) => {
            if (!primaryCategory) {
                const fetchCategoryPrimary = await getCategoryPrimary();
                if (fetchCategoryPrimary.error) {
                    setLoading(false);
                    setError(fetchCategoryPrimary.error);
                }
                dispatch(setPrimaryCategory(fetchCategoryPrimary.data));
                resolve(fetchCategoryPrimary.data);
            } else {
                resolve(primaryCategory);
            }
        });
        promise.then(async categoryPrimary => {
            if (categoryPrimary?.length > 0 && primaryId) {
                const foundItem = categoryPrimary?.find(
                    cat => cat.slug === primaryId
                );
                if (!foundItem && categoryPrimary) {
                    navigate("/not-found");
                    setNotFound(true);
                }
                const fetchProductCategories = await getProductCategories(
                    foundItem?.id
                );
                if (
                    fetchProductCategories.error ||
                    fetchProductCategories.data.length === 0
                ) {
                    setLoading(false);
                    setError(fetchProductCategories.error);
                } else if (fetchProductCategories.data) {
                    dispatch(setProductCategories(fetchProductCategories.data));

                    if (fetchProductCategories.data[0]?.slug) {
                        navigate(
                            `/${primaryId}/${fetchProductCategories.data[0]?.slug}`
                        );
                    }
                }
            }
        });
    }, [primaryId]);
    let foundItem;

    if (primaryCategory) {
        foundItem = primaryCategory?.find(categ => categ.slug === primaryId);
    }
    return (
        <Layout
            hasMenu={true}
            categoryPrimary={primaryCategory}
            productCategories={productCategories}
            productsByCategory={null}
            defaultPrimary={foundItem}
        >
            <div className="col-12 bg-color-primary row mx-0 px-0">
                <ConfigHead
                    title={
                        productCategories[0]?.slug
                            ? `منو آیلی | ${productCategories[0]?.title ?? ""}`
                            : `منو آیلی `
                    }
                    description="Generated by create next app"
                />
                {loading && productCategories && <Spinner />}

                {!error && !loading && !productCategories[0]?.slug && (
                    <div className={classes.breadCrumb}>
                        <h2>{foundItem?.title}</h2>
                        {foundItem && <h2>/</h2>}
                        <h2> {productCategories[0]?.title}</h2>
                    </div>
                )}

                {!error && !loading && !productCategories[0]?.slug && (
                    <EmptyItem
                        content={`${foundItem?.title} هنوز راه اندازی نشده !`}
                    />
                )}

                {error && !notFound && (
                    <>
                        <Error
                            myError={error}
                            setOpenError={setOpenError}
                            openError={openError}
                        />
                        <div className={classes.notfound}>
                            <Button
                                clicked={() => {
                                    window.location.reload();
                                }}
                            >
                                تلاش مجدد
                            </Button>
                        </div>
                    </>
                )}
            </div>
        </Layout>
    );
}
