import classNames from "classnames";
import { NavLink, useLocation } from "react-router-dom";
import classes from "./NavbarItem.module.scss";
const NavbarItem = ({ link, children }) => {
    const location = useLocation();
    return (
        <li
            className={classNames({
                [classes.NavbarItem]: true,
                [classes.Active]: location.pathname === link,
                "col-4": true,
            })}
        >
            <NavLink to={link}>{children}</NavLink>
        </li>
    );
};

export default NavbarItem;
