import classes from "./Cart.module.scss";
import IconButton from "../../UI/Icon-Button/Icon-Button";
import classNames from "classnames";
import Backdrop from "../../UI/Backdrop/Backdrop";
import { MdEditNote } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { toggle } from "../../../store/ui-slice";
import CartItem from "./CartItem";
import { clearItemsFromCart } from "../../../store/cart-slice";
import { FiTrash2 } from "react-icons/fi";
import { BsX } from "react-icons/bs";
const Cart = () => {
    const dispatch = useDispatch();
    const cartIsVisible = useSelector(state => state.ui.cartIsVisible);
    const cartItems = useSelector(state => state.cart.items);
    const totalQuantity = useSelector(state => state.cart.totalQuantity);
    const totalCartPrice = useSelector(state => state.cart.totalCartPrice);
    const claerCartItems = () => {
        dispatch(clearItemsFromCart());
    };
    return (
        <div>
            {cartIsVisible && (
                <Backdrop
                    closeModal={() => dispatch(toggle())}
                    showModal={cartIsVisible}
                />
            )}
            <IconButton type="cart" clicked={() => dispatch(toggle())} />

            <article
                className={classNames({
                    [classes.Cart]: true,
                    [classes.OpenCart]: cartIsVisible,
                    [classes.closeCart]: !cartIsVisible,
                    "col-12": true,
                })}
            >
                <div className={classes.TopSecrion}>
                    <div
                        className={classNames({
                            [classes.backContainer]: true,
                        })}
                        onClick={() => dispatch(toggle())}
                    >
                        <span className={classes.backIcon}>
                            <BsX />
                        </span>
                    </div>
                    <div className={classes.EditContainer}>
                        <div className={classes.Edit}>
                            <span className={classes.editIcon}>
                                <MdEditNote />
                            </span>
                        </div>
                        <div className={classes.cartHeader}>
                            <h6 className="color-primary">
                                در صورت کامل بودن سفارشات، منتظر همکاران برای
                                ثبت نهایی باشید.
                            </h6>
                        </div>
                    </div>
                    <div className={classes.TrashContainer}>
                        <div
                            className={classNames({
                                [classes.Trash]: true,
                                [classes.TrashOpen]: totalQuantity >= 2,
                                [classes.TrashClose]: totalQuantity <= 1,
                            })}
                            onClick={claerCartItems}
                        >
                            <h6>حذف همه</h6>
                            <span className={classes.trashIcon}>
                                <FiTrash2 />
                            </span>
                        </div>
                    </div>
                </div>
                <div className={classes.CartContainer}>
                    {totalQuantity > 0 ? (
                        <div className="col-12 h-100 mx-0 px-2 d-flex justify-content-center align-items-center">
                            <div
                                className={classNames({
                                    "col-12 h-100 row": true,
                                    [classes.CartItem]: true,
                                })}
                            >
                                {cartItems?.map(item => (
                                    <CartItem
                                        key={item.id}
                                        id={item.id}
                                        title={item.title}
                                        image={item.image}
                                        description={item.description}
                                        price={item.price}
                                        quantity={item.quantity}
                                    />
                                ))}
                            </div>
                        </div>
                    ) : (
                        <div className={classes.emptyContainer}>
                            <h4 className=" center color-danger d-flex flex-column mt-5">
                                {" "}
                                لیست سفارش شما خالی هست!
                            </h4>
                        </div>
                    )}
                </div>
                <div className={classes.CartFooter}>
                    <div className={classes.totalPrice}>
                        <h3>جمع مبالغ:</h3>
                        <div className="d-flex">
                            <h3> {totalCartPrice.toLocaleString(3)} </h3>
                            <h3 className={classes.Price}> تومان </h3>
                        </div>
                    </div>
                    <p className="center">
                        برای سفارش، یادداشت خود را اعلام کنید.
                    </p>
                </div>
            </article>
        </div>
    );
};

export default Cart;
