import classNames from "classnames";
import classes from "./MenuHeader.module.scss";
import Selectbar from "../Selectbar/Selectbar";
import MenuList from "../../Menu/MenuList";
import Cart from "../Cart/Cart";
import Search from "../Search/Search";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { NavLink } from "react-router-dom";
import { useState } from "react";
import IconButton from "src/components/UI/Icon-Button/Icon-Button";
import imageLogo from "src/assets/images/Logo-Original2.png";

const MenuHeader = ({ categoryPrimary, defaultPrimary }) => {
    const [showModal, setShowModal] = useState(false);
    setTimeout(() => {
        setShowModal(false);
    }, 500);
    return (
        <nav
            className={classNames({
                [classes.MenuHeader]: true,
                "col-12  mx-0 px-0": true,
            })}
        >
            <div className={classes.backGround}></div>
            <ul className="col-12 mx-0 px-0 h-100">
                <li className="col-4 bg-color-warning h-100 m-0 p-0">
                    <div className={classes.rightMenu}>
                        <MenuList />
                        <Cart />
                    </div>
                </li>

                <li
                    className={classNames({
                        "col-4 bg-color-warning h-100 position-relative  mx-0 px-0 py-0 d-flex justify-content-center align-items-center": true,
                        [classes.centerMenu]: true,
                    })}
                >
                    {categoryPrimary && defaultPrimary ? (
                        <Selectbar
                            categoryPrimary={categoryPrimary}
                            defaultPrimary={defaultPrimary}
                        />
                    ) : (
                        <SkeletonTheme
                            baseColor="#cb8438"
                            highlightColor="#e5af75"
                        >
                            <Skeleton
                                className={classNames({
                                    " w-100 h-100 mx-0 px-0 py-0 position-absolute": true,
                                    [classes.Selecton]: true,
                                })}
                            />
                        </SkeletonTheme>
                    )}
                </li>

                <li className="col-4 bg-color-warning h-100 p-0 m-0">
                    <div className={classes.leftMenu}>
                        <div className="col-6 text-white text-center d-flex justify-content-center px-0 ">
                            <IconButton
                                clicked={() => {
                                    setShowModal(true);
                                }}
                                type="search"
                            />
                            <div className="col-0">
                                <Search initialModal={showModal} />
                            </div>
                        </div>
                        <ul className="col-6 text-white position-relative text-center px-0 ">
                            <li className={classes.logoContainer}>
                                <NavLink to="/" className={classes.Logo}>
                                    <img
                                        src={imageLogo}
                                        alt=""
                                        className={classes.Image}
                                    />
                                </NavLink>
                            </li>
                        </ul>
                    </div>
                </li>
            </ul>
        </nav>
    );
};

export default MenuHeader;
