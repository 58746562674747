import { Fragment, useState } from "react";
import classes from "./MenuList.module.scss";
import MenuItem from "./MenuItem";
import classNames from "classnames";
import { BsX } from "react-icons/bs";
import Backdrop from "../UI/Backdrop/Backdrop";
import { IoHome } from "react-icons/io5";
import { BsInfoCircle, BsFillGiftFill } from "react-icons/bs";
import IconButton from "../UI/Icon-Button/Icon-Button";
import { useParams } from "react-router";

const MenuList = () => {
    const [openMenubar, setOpenMenubar] = useState(false);
    
    const {primaryId, categoryId} = useParams();
    const categoryRoute= `/${primaryId}/${categoryId}`;
    const primaryRoute= `/${primaryId}`;
    let link;
    if(categoryId){
        link= categoryRoute;
    }else if (primaryId){
        link= primaryRoute;
    }else{
        link= '/cafe';
    }
    
    return (
        <Fragment>
            <div className=" h-100 mx-0 px-0 row">
                {openMenubar && (
                    <Backdrop
                        closeModal={() => setOpenMenubar(false)}
                        showModal={openMenubar}
                    />
                )}
                <IconButton type='menu' clicked={() => setOpenMenubar(true)} />
                <nav
                    className={classNames({
                        [classes.MenuList]: true,
                        [classes.OpenMenuList]: openMenubar,
                        [classes.CloseMenuList]: !openMenubar,
                        "my-0 py-0 d-xl-none": true,
                    })}
                >
                    <ul
                        className={classNames({
                            [classes.sideMenuList]: true,
                            "my-3 px-3": true,
                        })}
                    >
                        <li className="col-12 text-end d-flex justify-content-end align-items-end mx-0 px-0 py-3">
                            <span
                                className={classes.bsxIcon}
                                onClick={() => setOpenMenubar(false)}
                            >
                                <BsX />
                            </span>
                        </li>

                        <MenuItem
                            link={link}
                            clicked={() => setOpenMenubar(false)}
                        >
                            {" "}
                            <span className={classes.menuIcon}>
                                <IoHome />
                            </span>
                            خانه
                        </MenuItem>

                        <MenuItem
                            link="/birthday"
                            clicked={() => setOpenMenubar(false)}
                        >
                            {" "}
                            <span className={classes.menuIcon}>
                                <BsFillGiftFill />
                            </span>
                            شرایط رزرو تولد 
                        </MenuItem>
                        <MenuItem
                            link="/about"
                            clicked={() => setOpenMenubar(false)}
                        >
                            <span className={classes.menuIcon}>
                                <BsInfoCircle />
                            </span>
                            درباره ی ما
                        </MenuItem>
                    </ul>
                </nav>
            </div>
        </Fragment>
    );
};

export default MenuList;
