import classes from "./Select.module.scss";
import { useState } from "react";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { useNavigate, useParams } from "react-router";
import Icon from "src/assets/icons/Icon";
import { NavLink } from "react-router-dom";
import { BsChevronDown } from "react-icons/bs";
import classNames from "classnames";

const Selectbar = ({ categoryPrimary, defaultPrimary }) => {
    const navigate = useNavigate();
    const [openOption, setOpenOption] = useState(false);
    const { primaryId } = useParams();
    const clicedLink = link => {
        setOpenOption(false);
        navigate(link);
    };
    return (
        <div className="col-12 h-100">
            {categoryPrimary?.length > 0 && defaultPrimary ? (
                <div className={classes.Select}>
                    <div
                        className={classes.defaultOption}
                        onClick={() => setOpenOption(!openOption)}
                    >
                        <div className={classes.titleContainer}>
                            <span className={classes.icon}>
                                <Icon icon={defaultPrimary?.iconName} />
                            </span>
                            <span className={classes.title}>
                                {defaultPrimary?.title}
                            </span>
                        </div>
                        <span className={classes.outlineIcon}>
                            <BsChevronDown />
                        </span>
                    </div>
                    {openOption && (
                        <div className={classes.SelectContainer}>
                            <nav className={classes.SelectNav}>
                                <ul className={classes.Items}>
                                    {categoryPrimary?.map(category => (
                                        <li
                                            key={category?.id}
                                            className={classNames({
                                                [classes.Item]: true,
                                                [classes.activeItem]:
                                                    primaryId ===
                                                    category?.slug,
                                            })}
                                            onClick={() =>
                                                clicedLink(`/${category.slug}`)
                                            }
                                        >
                                            <Icon icon={category?.iconName} />
                                            <NavLink to={`/${category.slug}`}>
                                                {" "}
                                                {category?.title}
                                            </NavLink>
                                        </li>
                                    ))}
                                </ul>
                            </nav>
                        </div>
                    )}
                </div>
            ) : (
                <SkeletonTheme baseColor="#cb8438" highlightColor="#e5af75">
                    <Skeleton height={55} className={classes.Select} />
                </SkeletonTheme>
            )}
        </div>
    );
};

export default Selectbar;
