import React from "react";
import ReactDOM from "react-dom/client";
// add bootstrap for styling
import "bootstrap/dist/css/bootstrap.rtl.min.css";
// add sceleton for styling
import "react-loading-skeleton/dist/skeleton.css";
// add swiper for styling
import "swiper/css/bundle";
// add redux store and storage
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { initStore, presister } from "./store";
// add react-toastify stying
import "react-toastify/dist/ReactToastify.css";
import ImageModal from "./components/UI/ImageModal/ImageModal";
// add router
import { BrowserRouter as Router } from "react-router-dom";
import "./index.scss";
import App from "./App";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
    <React.StrictMode>
        <Provider store={initStore}>
            <PersistGate loading={null} persistor={presister}>
                <Router>
                    <App />
                    <ImageModal />
                </Router>
            </PersistGate>
        </Provider>
    </React.StrictMode>
);
