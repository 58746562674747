import classes from "./BirthDay.module.scss";
import { Swiper, SwiperSlide } from "swiper/react";
import GalleryImage from "src/components/UI/GaleryCard/GalleryCard";
import classNames from "classnames";
import { useRef, useState } from "react";
import { BsArrowRight, BsArrowLeft } from "react-icons/bs";

const BirthDay = ({ information }) => {
    const { birtDayInfo, imageGallery } = information;
    const sliderRef = useRef(null);
    const [slideId, setSlideId] = useState(0);

    const handleGoToNext = value => {
        sliderRef.current.swiper.slideTo(value);
        setSlideId(value);
    };
    const onChangeSlideHnadler = value => {
        setSlideId(value.activeIndex);
    };

    return (
        <>
            <ul
                className={classNames({
                    [classes.Navbar]: true,
                    "d-lg-none ": true,
                })}
            >
                <li
                    className={slideId === 0 && classes.Active}
                    onClick={() => handleGoToNext(0)}
                >
                    شرایط رزرو تولد
                </li>
                <li
                    className={slideId === 1 && classes.Active}
                    onClick={() => handleGoToNext(1)}
                >
                    تصاویر تولد
                </li>
            </ul>
            <div
                className={classNames({
                    "d-flex px-5 d-flex d-lg-none": true,
                    [classes.changeRightPage]: slideId === 0,
                    [classes.changeLeftPage]: slideId === 1,
                })}
            >
                {slideId === 0 ? (
                    <>
                        <p>ورق بزنید</p>
                        <span>
                            <BsArrowLeft />
                        </span>
                    </>
                ) : (
                    <>
                        <span>
                            <BsArrowRight />
                        </span>
                        <p>ورق بزنید</p>
                    </>
                )}
            </div>
            <div className={classes.BirthDay}>
                <div className="d-inline col-12 h-100 d-lg-none ">
                    <Swiper
                        effect={"coverflow"}
                        slidesPerView={"auto"}
                        loop={false}
                        allowTouchMove={true}
                        pagination={false}
                        grabCursor={true}
                        speed={1000}
                        threshold={3}
                        ref={sliderRef}
                        onSlideChange={value => onChangeSlideHnadler(value)}
                        className={classes.swiper}
                    >
                        <SwiperSlide>
                            <div className="col-10 offset-1 col-lg-6 ">
                                <div
                                    className={classes.Container}
                                    dangerouslySetInnerHTML={{
                                        __html: birtDayInfo,
                                    }}
                                />
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div
                                className={classNames({
                                    [classes.imageContainer]: true,
                                    "col-12 mx-0 px-0": true,
                                })}
                            >
                                {imageGallery.map(image => (
                                    <GalleryImage
                                        smallDevice={true}
                                        name={image.title}
                                        image={image.image}
                                        id={image.id}
                                        key={image.id}
                                    />
                                ))}
                            </div>
                        </SwiperSlide>
                    </Swiper>
                </div>
                <div className="col-12 d-lg-flex align-items-center justify-content-center d-none">
                    <div className="col-6 h-100 px-5">
                        <div
                            className={classes.Container}
                            dangerouslySetInnerHTML={{
                                __html: information.birtDayInfo,
                            }}
                        />
                    </div>

                    <div className="col-6 h-100">
                        <div
                            className={classNames({
                                [classes.imageContainer]: true,
                                "col-11 mx-0 px-0": true,
                            })}
                        >
                            {imageGallery.map(image => (
                                <GalleryImage
                                    smallDevice={false}
                                    name={image.title}
                                    image={image.image}
                                    id={image.id}
                                    key={image.id}
                                />
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default BirthDay;
