import classNames from "classnames";
import classes from "./Card.module.scss";
import { BsFillHeartFill, BsHeart } from "react-icons/bs";
import { addItemToCart, removeItemFromCart } from "../../../store/cart-slice";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import { FiTrash2 } from "react-icons/fi";
import { ToastContainer, toast } from "react-toastify";
import { useNavigate } from "react-router";
import { postUserLike } from "../../../allRequest";
import { imageUrl } from "src/helper/url-config";
const Card = ({
    image,
    title,
    price,
    description,
    heart,
    id,
    parentRoute,
    slug,
    getProductsByCategory,
    productCategoryId,
    isLiked,
}) => {
    const dispatch = useDispatch();
    const cartItems = useSelector(state => state.cart.items);
    const foundItems = cartItems?.findIndex(item => item.id === id);
    const quantity = cartItems[foundItems]?.quantity;
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const { visitorId } = useSelector(state => state.userStatus);
    const addItemHandler = () => {
        dispatch(
            addItemToCart({
                id,
                title,
                price,
                image,
                heart,
                description,
            })
        );
    };
    const removeItemHandler = () => {
        dispatch(removeItemFromCart({ id }));
    };

    const heartThouched = productId => {
        setLoading(true);
        const errorMassage = errorMassage => {
            toast.error(errorMassage, {
                position: "bottom-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        };
        if (productId && visitorId) {
            postUserLike({ productId, visitorId }).then(like => {
                if (like.error) {
                    setLoading(false);
                    errorMassage(like.error);
                }
                if (like.isSuccess && !like.error) {
                    getProductsByCategory({
                        parentId: productCategoryId,
                        visitorId,
                    }).then(data => {
                        setLoading(false);
                        if (data.error) {
                            errorMassage(data.error);
                        }
                    });
                } else return;
            });
        }
    };
    return (
        <div className="col-xs-12 col-sm-6 col-lg-4 bg-color-primary mt-4">
            <div
                className={classNames({
                    "col-12 bg-color-dark align-center justify-content-between px-0": true,
                    [classes.Card]: true,
                })}
                onClick={() => navigate(`${parentRoute}/${slug}`)}
            >
                <div className="col-4 col-md-5 h-100 align-center">
                    <div
                        className={classNames({
                            [classes.image]: true,
                            "mx-auto": true,
                        })}
                    >
                        {image && <img src={`${imageUrl}/${image}`} alt="" />}
                    </div>
                </div>

                <div className="col-5 col-sm-4 align-start flex-column h-100 pt-4 pr-4 px-2">
                    <h5 className="color-white "> {title}</h5>
                    <div className="d-flex ">
                        <h6 className="color-warning d-inline mx-1 mb-0 pb-0">
                            {" "}
                            {price.toLocaleString(3)}
                        </h6>
                        <h6
                            className={classNames({
                                "color-warning d-inline mb-0 pb-0": true,
                                [classes.price]: true,
                            })}
                        >
                            {" "}
                            تومان{" "}
                        </h6>
                    </div>

                    <div className="color-light mb-0 pb-0">
                        <div
                            className={classNames({
                                "color-light center": true,
                                [classes.description]: true,
                            })}
                            dangerouslySetInnerHTML={{ __html: description }}
                        />
                    </div>
                </div>

                <div
                    className={classNames({
                        "col-3 d-flex justify-content-around align-items-center flex-column h-100 ": true,
                        [classes.buyList]: true,
                    })}
                >
                    {!loading ? (
                        <div className="col-12 color-white center h-25 row mx-0 px-0">
                            <div
                                className={classNames({
                                    " col-6 text-start mx-0 px-0 py-0 h-100": true,
                                    [classes.heartNumber]: true,
                                })}
                            >
                                <h6 className="py-0 my-0 d-felx align-items-center">
                                    {heart >= 99 ? "+99" : heart}
                                </h6>
                            </div>
                            <span
                                className="color-danger col-6 text-end px-0 px-0 "
                                onClick={e => {
                                    e.stopPropagation();
                                    heartThouched(id);
                                }}
                            >
                                {!isLiked ? <BsHeart /> : <BsFillHeartFill />}

                                <ToastContainer
                                    className={classes.tostify}
                                    position="bottom-right"
                                    autoClose={5000}
                                    hideProgressBar={false}
                                    newestOnTop={false}
                                    closeOnClick
                                    rtl={false}
                                    pauseOnFocusLoss
                                    draggable
                                    pauseOnHover
                                />
                            </span>
                        </div>
                    ) : (
                        <div className="col-12 pt-2 h-25 text-center ">
                            <div
                                className=" spinner-border color-danger spinner-border-sm"
                                role="status"
                            ></div>
                        </div>
                    )}

                    <div
                        className={classNames({
                            [classes.numberContainer]: true,
                            [classes.numberContainer2]:
                                typeof quantity === "undefined",
                            " btn-group col-12 color-light center": true,
                        })}
                        onClick={e => e.stopPropagation()}
                    >
                        <button
                            type="button"
                            className="btn btn-white btn-sm "
                            onClick={addItemHandler}
                        >
                            +
                        </button>
                        {quantity > 0 && (
                            <>
                                <span
                                    className={classNames({
                                        " center align-center": true,
                                        [classes.Count]: true,
                                    })}
                                >
                                    {!quantity ? 0 : quantity}
                                </span>
                                <button
                                    type="button"
                                    className="btn btn-white btn-sm"
                                    onClick={removeItemHandler}
                                >
                                    {quantity > 1 ? (
                                        "-"
                                    ) : quantity === 1 ? (
                                        <FiTrash2 />
                                    ) : (
                                        " "
                                    )}
                                </button>
                            </>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Card;
