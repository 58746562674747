import Header from "../Header/Header";
import Menubar from "../../components/Menubar/Menubar";
import classNames from "classnames";
import classes from "./Layout.module.scss";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import { setShowMenubar } from "src/store/ui-slice";

const Layout = ({
    children,
    hasMenu,
    categoryPrimary,
    productCategories,
    defaultPrimary,
    productsByCategory,
}) => {
    const [changedPage, setChangedPage] = useState(false);
    const { cartIsVisible, showMenubar } = useSelector(state => state.ui);
    const [realIndex, setRealIndex] = useState(0);
    const { categoryId } = useParams();
    const dispath = useDispatch();

    useEffect(() => {
        setChangedPage(false);

        window.addEventListener("scroll", () => {
            if (window.scrollY > 1 && productsByCategory?.length > 3) {
                dispath(setShowMenubar(false));
                setChangedPage(true);
            } else if (window.scrollY === 0 && productsByCategory?.length > 3) {
                dispath(setShowMenubar(true));
                setChangedPage(true);
            }
        });
        if (productCategories && productCategories[0]?.slug && categoryId) {
            const updatedIndex = productCategories?.findIndex(
                item => item?.slug === categoryId
            );
            setRealIndex(updatedIndex);
        }
    }, [realIndex, categoryId, productCategories, productsByCategory, dispath]);

    return (
        <div className="container-well  ">
            <div
                className={classNames({
                    "row mx-0 px-0": true,
                    [classes.notScroll]: cartIsVisible,
                })}
            >
                {/* menubar */}
                {hasMenu && (
                    <section
                        className={classNames({
                            "col-12 col-xl-3 bg-color-primary mx-0 px-0": true,
                            [classes.Menubar]: true,
                        })}
                    >
                        <Menubar
                            showMenubar={showMenubar}
                            changedPage={changedPage}
                            setChangedPage={setChangedPage}
                            categoryPrimary={categoryPrimary}
                            productCategories={productCategories}
                            defaultPrimary={defaultPrimary}
                            realIndex={realIndex}
                        />
                    </section>
                )}
                {/* header */}
                <div
                    className={classNames({
                        "col-12 col-xl-9 mx-0 px-0 bg-color-primary": hasMenu,
                        "col-12 px-0 mx-0 bg-color-primary": !hasMenu,
                    })}
                >
                    <header
                        className={
                            hasMenu
                                ? "col-12 d-none d-xl-inline "
                                : "col-12 col-xl-9 offset-xl-3"
                        }
                    >
                        <Header />
                    </header>
                    {/* main */}
                    <main
                        className={classNames({
                            "col-12": true,
                            [classes.main]: !showMenubar && hasMenu,
                            [classes.smallMain]: showMenubar && hasMenu,
                        })}
                    >
                        <div className="col-12 mx-0 px-0 h-100">{children}</div>
                    </main>
                </div>
            </div>
            {/* footer section */}
        </div>
    );
};

export default Layout;
