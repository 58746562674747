import classes from "./Spinner.module.scss";
const Spinner = () => {
    return (
        <>
            <div className={classes.backdrop}></div>
            <div className={classes.Spinner}>
                <span className={classes.loader}></span>
                <span className={classes.loading}>در حال بارگیری...</span>
            </div>
        </>
    );
};
export default Spinner;
