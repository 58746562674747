import { Navigate, Route, Routes } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { getVisitorId } from "./store/userStatus-slice";
import HomePage from "./pages/home-page";
import ProductsPage from "./pages/home-page/products-page";
import SinglePage from "./pages/home-page/products-page/single-page";
import BirthDayPage from "./pages/birthday";
import AboutPage from "./pages/about";
import NotFound404 from "./pages/404";
import NotFound from "./pages/not-found";

function App() {
    const fpPromise = import("./assets/fingerPrint/v3").then(FingerprintJS =>
        FingerprintJS.load()
    );
    const { visitorId } = useSelector(state => state.userStatus);
    const dispatch = useDispatch();

    if (!visitorId) {
        fpPromise
            .then(fp => fp.get())
            .then(result => {
                const visitorId = result.visitorId;
                dispatch(getVisitorId(visitorId));
            });
    }
    return (
        <Routes>
            <Route path="*" element={<Navigate replace to={`/cafe`} />} />

            <Route path="birthday" element={<BirthDayPage />} />
            <Route path="about" element={<AboutPage />} />
            <Route path="404" element={<NotFound404 />} />
            <Route path="not-found" element={<NotFound />} />

            <Route path=":primaryId">
                <Route index element={<HomePage />} />
                <Route path=":categoryId">
                    <Route index element={<ProductsPage />} />
                    <Route path=":productId">
                        <Route index element={<SinglePage />} />
                    </Route>
                </Route>
            </Route>
        </Routes>
    );
}

export default App;
