import classes from "./SingleProduct.module.scss";
import classNames from "classnames";
import { BsFillHeartFill, BsHeart } from "react-icons/bs";
import { MdEditNote } from "react-icons/md";
import { AiOutlineRight, AiFillEye } from "react-icons/ai";
import { FiTrash2 } from "react-icons/fi";
import { imageUrl } from "src/helper/url-config";
import { useDispatch, useSelector } from "react-redux";
import { addItemToCart, removeItemFromCart } from "../../store/cart-slice";
import { useState } from "react";
import { postUserLike, getProductsBySlug } from "src/allRequest";
import { ToastContainer, toast } from "react-toastify";
import { useNavigate } from "react-router";
import Icon from "src/assets/icons/Icon";

const SingleProduct = ({ product, parentRoute }) => {
    const [updatedProduct, setUpdatedProduct] = useState(null);
    const {
        indexImage,
        price,
        title,
        description,
        likeCount,
        id,
        visitCount,
        slug,
        isLiked,
        categoryIcon,
    } = updatedProduct ?? product;
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const { visitorId } = useSelector(state => state.userStatus);
    const addItemHandler = () => {
        dispatch(
            addItemToCart({
                id,
                price,
                title,
                image: indexImage,
            })
        );
    };
    const removeItemHandler = () => {
        dispatch(removeItemFromCart({ id }));
    };
    const cartItems = useSelector(state => state.cart.items);
    const foundItems = cartItems?.findIndex(item => item.id === id);
    const quantity = cartItems[foundItems]?.quantity;
    const zeroQuantity = typeof quantity === "undefined" || quantity === 0;
    const heartThouched = productId => {
        setLoading(true);
        const errorMassage = errorMassage => {
            toast.error(errorMassage, {
                position: "bottom-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        };
        if (productId && visitorId) {
            postUserLike({ productId, visitorId }).then(like => {
                if (like.error) {
                    setLoading(false);
                    errorMassage(like.error);
                }
                if (like.isSuccess && !like.error) {
                    getProductsBySlug({ slug, visitorId }).then(data => {
                        setLoading(false);
                        setUpdatedProduct(data?.data);
                        if (data.error) {
                            errorMassage(data.error);
                        }
                    });
                } else return;
            });
        }
    };
    return (
        <div className={classes.Product}>
            <div className={classes.ImageContainer}>
                <div className={classes.image}>
                    <img src={`${imageUrl}/${indexImage}`} alt="" />
                </div>

                <div
                    onClick={() => {
                        navigate(parentRoute);
                    }}
                >
                    <span className={classes.backIcon}>
                        <AiOutlineRight />
                    </span>
                </div>
            </div>
            <div className={classes.ProductDetail}>
                <div className="col-12 position-relative d-flex flex-column h-100">
                    <div
                        className={classNames({
                            "col-8 offset-2 col-sm-3 offset-sm-6 ": true,
                            [classes.productStatus]: true,
                        })}
                    >
                        <div className="col-6 mx-auto d-flex justify-content-center align-items-center">
                            <span className="color-light">
                                {loading && (
                                    <div className="col-6 text-center d-flex align-items-center">
                                        <div
                                            className=" spinner-border color-light spinner-border-sm"
                                            role="status"
                                        ></div>
                                    </div>
                                )}
                                <ToastContainer
                                    className={classes.tostify}
                                    position="bottom-right"
                                    autoClose={5000}
                                    hideProgressBar={false}
                                    newestOnTop={false}
                                    closeOnClick
                                    rtl={false}
                                    pauseOnFocusLoss
                                    draggable
                                    pauseOnHover
                                />
                                {!loading && (
                                    <span
                                        className={classes.Like}
                                        onClick={() => heartThouched(id)}
                                    >
                                        {!isLiked ? (
                                            <BsHeart />
                                        ) : (
                                            <BsFillHeartFill />
                                        )}
                                    </span>
                                )}

                                <p className={classes.heartNumber}>
                                    {likeCount >= 99999 ? "99999+" : likeCount}
                                </p>
                            </span>
                        </div>

                        <div className="col-6 mx-auto">
                            <span className="color-light">
                                <AiFillEye />
                                <p className={classes.EyeNumber}>
                                    {visitCount >= 99999
                                        ? "99999+"
                                        : visitCount}
                                </p>
                            </span>
                        </div>
                    </div>
                    <div
                        className={classNames({
                            "col-12 col-sm-10 offset-sm-1  px-3 mt-3 d-flex align-items-center ": true,
                            [classes.productTitle]: true,
                        })}
                    >
                        <div className="col-8 h-100">
                            <h2 className="h-100 p-0 m-0 color-white d-flex justify-content-center align-items-center">
                                <span className="mx-1">
                                    {" "}
                                    <Icon icon={categoryIcon} />
                                </span>

                                {title}
                            </h2>
                        </div>
                        <div className="col-4 d-flex align-items-center justify-content-center color-light h-100 center">
                            <h4 className="h-100 d-flex align-items-center pt-1">
                                {price.toLocaleString(3)}
                            </h4>
                            <h4 className="h-100 d-flex align-items-center px-1 pt-1 ">
                                تومان
                            </h4>
                        </div>
                    </div>
                    <div
                        className={classNames({
                            "col-12 col-sm-8 offset-sm-3 px-4 mt-5 color-light": true,
                            [classes.description]: true,
                        })}
                    >
                        <div
                            className="center"
                            dangerouslySetInnerHTML={{ __html: description }}
                        />
                    </div>
                    <div
                        className={classNames({
                            [classes.numberContainer]: true,
                            "col-4 col-sm-2 offset-4 offset-sm-6 btn-group color-light mt-3":
                                !zeroQuantity,
                            "col-8 col-sm-4 offset-2 offset-sm-5 btn-group color-light mt-3":
                                zeroQuantity,
                        })}
                    >
                        {zeroQuantity ? (
                            <button
                                type="button"
                                className={classNames({
                                    "btn btn-white btn-sm ": true,
                                    [classes.note]: true,
                                })}
                                onClick={addItemHandler}
                            >
                                <span className={classes.editIcon}>
                                    <MdEditNote />
                                </span>
                                افزودن به یادداشت
                            </button>
                        ) : (
                            <>
                                <button
                                    type="button"
                                    className="btn btn-white btn-sm "
                                    onClick={addItemHandler}
                                >
                                    +
                                </button>
                                <span
                                    className={classNames({
                                        " center align-center color-white": true,
                                        [classes.Count]: true,
                                    })}
                                >
                                    {quantity}
                                </span>
                                <button
                                    type="button"
                                    className="btn btn-white btn-sm"
                                    onClick={removeItemHandler}
                                >
                                    {quantity > 1 ? "-" : <FiTrash2 />}
                                </button>
                            </>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SingleProduct;
