import classNames from "classnames";
import classes from "./Error.module.scss";
import Backdrop from "../UI/Backdrop/Backdrop";
import Button from "../UI/Button/Button";

const Error = ({ myError, showError, setOpenError, openError }) => {
    return (
        <>
            {openError && (
                <Backdrop
                    closeModal={() => setOpenError(false)}
                    showModal={openError}
                />
            )}
            {openError && (
                <section
                    className={classNames({
                        [classes.Error]: true,
                        [classes.openError]: openError || showError,
                        [classes.closeError]: !openError,
                    })}
                >
                    <h4>{myError}</h4>
                    <Button
                        clicked={() => {
                            setOpenError(false);
                            window.location.reload();
                        }}
                        btnType="button"
                    >
                        تلاش مجدد
                    </Button>
                </section>
            )}
        </>
    );
};

export default Error;
