import classes from "./Search.module.scss";
import { useState, useRef, Fragment, useEffect } from "react";
import { BiSearchAlt } from "react-icons/bi";
import classNames from "classnames";
import Backdrop from "src/components/UI/Backdrop/Backdrop";
import { FiTrash2 } from "react-icons/fi";
import { BsArrowRight } from "react-icons/bs";
import SearchItem from "./Search-Item";
import { searchByProductName } from "src/allRequest";

const Search = ({ initialModal }) => {
    const [value, setValue] = useState("");
    const inputRef = useRef(null);
    const [showModal, setShowModal] = useState(initialModal);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [filterProducts, setFilterProducts] = useState(null);
    useEffect(() => {
        if (initialModal) {
            setShowModal(initialModal);
        }
    }, [initialModal]);
    const changeInputhander = event => {
        setValue(event.target.value);
        setLoading(true);
        const enteredValue = inputRef?.current.value;

        if (enteredValue.trim().length === 0) {
            setLoading(false);
            setError(null);
        }
        if (enteredValue.trim().length > 0) {
            setTimeout(() => {
                const searchByName = async () => {
                    const search = await searchByProductName(enteredValue);
                    return search;
                };
                searchByName().then(products => {
                    setLoading(false);
                    setError(null);
                    if (products.error) {
                        setError(products.error);
                    }
                    if (products.data?.length === 0) {
                        setError("هیچ ایتمی پیدا نشد!");
                    }
                    setFilterProducts(products.data);
                });
            }, 100);
        }
    };
    const clearProducts = () => {
        setValue("");
        setFilterProducts(null);
        setError(null);
    };
    useEffect(() => {
        if (value.length < 2) {
            setFilterProducts(null);
            setError(null);
        }
    }, [value]);
    return (
        <Fragment>
            <Backdrop
                showModal={showModal}
                closeModal={() => {
                    setShowModal(false);
                    setLoading(false);
                }}
            />

            <div
                onClick={() => {
                    inputRef.current.focus();
                }}
                className={classNames({
                    "col-6 col-xl-4  row  mx-auto position-relative": true,
                    [classes.container]: true,
                })}
            >
                <div
                    onClick={() => {
                        inputRef.current.focus();
                    }}
                    className={classNames({
                        "col-12 mx-0 px-0 mx-auto": true,
                        [classes.Form]: true,
                        [classes.formNotActive]: !showModal,
                        [classes.formEmpty]: !filterProducts && showModal,
                        [classes.FormActive]: showModal,
                        [classes.formNotFound]: error && showModal,
                        [classes.formFound]: !error && showModal,
                    })}
                >
                    <form
                        onSubmit={event => event.preventDefault()}
                        className="col-12 mx-auto d-flex justify-content-start sticky-lg-top"
                        onClick={() => {
                            setShowModal(true);
                            setError(null);
                        }}
                    >
                        <div
                            className="col-2 text-center justify-content-center align-itmes-center"
                            onClick={e => {
                                e.stopPropagation();
                                setShowModal(false);
                            }}
                            style={{ zIndex: "200" }}
                        >
                            <div className="d-flex justify-content-center d-xl-none">
                                <span className={classes.backIcon}>
                                    {showModal && <BsArrowRight />}
                                </span>
                                <span className={classes.SearchIcon}>
                                    {!showModal && <BiSearchAlt />}
                                </span>
                            </div>
                            <span className="d-none d-xl-flex">
                                <BiSearchAlt />
                            </span>
                        </div>
                        <div className="col-10 row">
                            <div className="col-10">
                                <input
                                    onChange={changeInputhander}
                                    value={value}
                                    placeholder="دنبال چه چیزی هستی؟"
                                    ref={inputRef}
                                />
                            </div>
                            {loading && (
                                <div className="col-2 mx-auto position-relative">
                                    <div
                                        className="position-absolute spinner-border spinner-border-sm text-dark mt-2 pt-2 ml-0 pl-0"
                                        role="status"
                                        style={{ left: "40%" }}
                                    ></div>
                                </div>
                            )}
                            {value && !loading && (
                                <div
                                    className={classNames({
                                        [classes.RemoveText]: true,
                                        "col-2 text": true,
                                    })}
                                >
                                    <span onClick={clearProducts}>
                                        <FiTrash2 />
                                    </span>
                                </div>
                            )}
                        </div>
                    </form>

                    <div className={classes.searchBorder}></div>
                    {(filterProducts?.length > 0 || error) && (
                        <nav
                            className={classNames({
                                [classes.NavList]: true,
                                "col-12": true,
                            })}
                        >
                            <ul className=" w-100 px-0 mx-0">
                                {showModal &&
                                    filterProducts?.map((item, index) => (
                                        <SearchItem
                                            id={index + item.id}
                                            key={index + item.id}
                                            link={item.path}
                                            image={item.indexImage}
                                            price={item.price}
                                            clicked={() => {
                                                setShowModal(false);
                                                setLoading(false);
                                            }}
                                        >
                                            {item.title
                                                .toLowerCase()
                                                .substring(0, 25)}
                                        </SearchItem>
                                    ))}
                                {showModal && error && (
                                    <li className={classes.notfound}>
                                        {error}
                                    </li>
                                )}
                            </ul>
                        </nav>
                    )}
                </div>
            </div>
        </Fragment>
    );
};

export default Search;
