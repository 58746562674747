import classNames from "classnames";
import classes from "./Products.module.scss";
import Card from "../UI/Card/Card";

const Products = ({ products, parentRoute, getProductsByCategory }) => {
    return (
        <div className="d-flex justify-content-center align-items-center px-0">
            <div
                className={classNames({
                    "col-12 row ": true,
                    [classes.Products]: true,
                })}
            >
                {products?.map(product => (
                    <Card
                        key={product.id}
                        id={product.id}
                        productCategoryId={product.productCategoryId}
                        title={product.title}
                        image={product.indexImage}
                        description={product.description}
                        price={product.price}
                        heart={product.likeCount}
                        parentRoute={parentRoute}
                        slug={product.slug}
                        getProductsByCategory={getProductsByCategory}
                        isLiked={product.isLiked}
                    />
                ))}
            </div>
        </div>
    );
};

export default Products;
