import SingleProduct from "src/components/SingleProduct/SingleProduct";
import { getProductsBySlug, postUserView } from "src/allRequest";
import ConfigHead from "src/components/ConfigHead/ConfigHead";
import { Fragment, useEffect, useState } from "react";
import Spinner from "src/components/Spinner/Spinner";
import { useSelector } from "react-redux";
import { memo, useCallback } from "react";
import { useNavigate, useParams } from "react-router";

const SinglePage = () => {
    const [singleProduct, setSingleProduct] = useState(null);
    const [loading, setLoading] = useState(true);
    const { categoryId, primaryId, productId } = useParams();
    const navigate = useNavigate();
    const { visitorId } = useSelector(state => state.userStatus);
    const fetchProductBySlug = useCallback(async () => {
        if (productId && visitorId) {
            const productBySlug = await getProductsBySlug({
                slug: productId,
                visitorId,
            });
            return productBySlug;
        }
    }, [productId]);

    useEffect(() => {
        fetchProductBySlug().then(data => {
            setLoading(false);
            if (data?.error) {
                navigate("/not-found");
            }
            setSingleProduct(data?.data);
            postUserView({ productId: data?.data?.id, visitorId });
        });
    }, [productId]);

    return (
        <Fragment>
            <ConfigHead
                title={
                    singleProduct?.title
                        ? `منو آیلی | ${singleProduct?.title ?? ""}`
                        : `منو آیلی `
                }
                description="Generated by create next app"
            />
            {singleProduct && (
                <SingleProduct
                    product={singleProduct}
                    parentRoute={`/${primaryId}/${categoryId}`}
                />
            )}
            {loading && <Spinner />}
        </Fragment>
    );
};
export default memo(SinglePage);
