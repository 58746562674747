import { configureStore } from "@reduxjs/toolkit";
import { combineReducers } from "redux";
import cartSlice from "./cart-slice";
import uiSlice from "./ui-slice";
import categoriesSlice from "./categories-slice";
import userStatusSlice from "./userStatus-slice";
// add storage for redux

import { persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { getDefaultMiddleware } from "@reduxjs/toolkit";

const presistConfig = {
    storage,
    key: "root",
    whitelist: ["userStatus", "cart"],
    blacklist: ["ui", "categories"],
};
const rootReducer = combineReducers({
    userStatus: userStatusSlice,
    cart: cartSlice,
    ui: uiSlice,
    categories: categoriesSlice,
});
const presitedReducer = persistReducer(presistConfig, rootReducer);

const customizedMiddleware = getDefaultMiddleware({
    serializableCheck: false,
});

export const initStore = configureStore({
    reducer: presitedReducer,
    middleware: customizedMiddleware,
});
export const presister = persistStore(initStore);
