import { BsReceiptCutoff } from "react-icons/bs";
import { GrTextAlignFull } from "react-icons/gr";
import { BiSearchAlt } from "react-icons/bi";
import classes from "./Icon-Button.module.scss";
import { useSelector } from "react-redux";

const IconButton = ({ clicked, type }) => {
    const cartQuantity = useSelector(state => state.cart.totalQuantity);
    return (
        <>
            <button className={classes.IconButton} onClick={clicked}>
                {type === "cart" && (
                    <div className={classes.Cart}>
                        <BsReceiptCutoff />
                        {cartQuantity > 0 && (
                            <span className={classes.Badge}>
                                {cartQuantity}
                            </span>
                        )}
                    </div>
                )}
                {type === "menu" && (
                    <span>
                        <GrTextAlignFull />
                    </span>
                )}
                {type === "search" && (
                    <span>
                        <BiSearchAlt />
                    </span>
                )}
            </button>
        </>
    );
};

export default IconButton;
