import classes from "./AboutUs.module.scss";

const AboutUs = ({ information }) => {
    return (
        <div className={classes.AboutUs}>
            <div
                className={classes.Container}
                dangerouslySetInnerHTML={{ __html: information.aboutUs }}
            />
            <div
                className={classes.Container}
                dangerouslySetInnerHTML={{ __html: information.contactUs }}
            />
        </div>
    );
};

export default AboutUs;
