import NavbarItem from "./NavbarItem";
import classes from "./NavbarList.module.scss";
import { IoHome } from "react-icons/io5";
import { BsInfoCircle, BsFillGiftFill } from "react-icons/bs";
import classNames from "classnames";
import { useParams } from "react-router";

const NavbarList = () => {
    const { primaryId, categoryId } = useParams();
    const categoryRoute = `/${primaryId}/${categoryId}`;
    const primaryRoute = `/${primaryId}`;
    let link;
    if (categoryId) {
        link = categoryRoute;
    } else if (primaryId) {
        link = primaryRoute;
    } else {
        link = "/cafe";
    }
    return (
        <ul
            className={classNames({
                [classes.NavbarList]: true,
                "col-5": true,
            })}
        >
            <NavbarItem link={link}>
                <span>
                    <IoHome />
                </span>
                خانه
            </NavbarItem>
            <NavbarItem link="/about">
                <span>
                    <BsInfoCircle />
                </span>
                درباره ما
            </NavbarItem>
            <NavbarItem link="/birthday">
                <span>
                    <BsFillGiftFill />
                </span>
                شرایط تولد
            </NavbarItem>
        </ul>
    );
};

export default NavbarList;
