import axios from "axios";

// import axios 
export const baseURL = process.env.REACT_APP_API_URL;

export const Axios = axios.create({
    baseURL: baseURL,
});

export const imageUrl = `${baseURL}api/v1/FileProperties/get-file`;
