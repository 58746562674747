import { Axios } from "../src/helper/url-config";

export const handleErrorMessage = error => {
    const completeError = error?.request.status;
    var message;
    switch (completeError) {
        case 0:
            message = "دسترسی شما به شبکه قطع شد !";
            break;
        case 400:
            message = "خطای  اطلاعات وارد شده!";
            break;
        case 401:
            message = " شما احراز هویت نشدید";
            break;
        case 403:
            message = " شما احراز هویت نشدید";
            break;
        case 404:
            message = "متاسفانه چیزی پیدا نشد!";
            break;
        case 405:
            message = " شبکه با مشکلی روبه رو شد !";
            break;
        case 422:
            message = "اطلاعات وارده اشتباه میباشد !";
            break;
        case 500:
            message = " شبکه با مشکلی روبه رو شد !";
            break;
        case 503:
            message = " شبکه با مشکلی روبه رو شد !";
            break;

        default:
            message = error;
    }
    return message;
};

export const getCategoryPrimary = async () => {
    try {
        const request = await Axios.get(`api/v1/ProductCategories/primary`);
        const { data } = request;
        return {
            error: null,
            data: data.data,
        };
    } catch (error) {
        return {
            error: handleErrorMessage(error),
            data: null,
        };
    }
};
export const getProductCategories = async primaryId => {
    try {
        const request = await Axios.get(
            `api/v1/ProductCategories/by-parent/${primaryId}`
        );
        const { data } = request;
        return {
            error: null,
            data: data.data,
        };
    } catch (error) {
        return {
            error: handleErrorMessage(error),
            data: null,
        };
    }
};
export const getProductsByCategory = async ({ parentId, visitorId }) => {
    try {
        const request = await Axios.get(
            `api/v1/Products/bycategory/${parentId}`,
            {
                headers: {
                    "content-type": "Application/json",
                    deviceId: visitorId,
                    accept: "text/plain",
                },
            }
        );
        const { data } = request;
        return {
            error: null,
            data: data.data,
        };
    } catch (error) {
        return {
            error: handleErrorMessage(error),
            data: null,
        };
    }
};
export const getProductsBySlug = async ({ slug, visitorId }) => {
    try {
        const request = await Axios.get(`api/v1/Products/slug/${slug}`, {
            headers: {
                "content-type": "Application/json",
                deviceId: visitorId,
                accept: "text/plain",
            },
        });
        const { data } = request;
        return {
            error: null,
            data: data.data,
        };
    } catch (error) {
        return {
            error: handleErrorMessage(error),
            data: null,
        };
    }
};
export const postUserLike = async ({ productId, visitorId }) => {
    try {
        const { data } = await Axios.post(
            `https://api.aylimenu.ir/api/v1/Products/reaction/${productId}`,
            {},
            {
                headers: {
                    "content-type": "Application/json",
                    deviceId: visitorId,
                    accept: "text/plain",
                },
            }
        );
        return {
            isSuccess: data.isSuccess,
            error: null,
        };
    } catch (error) {
        return {
            isSuccess: false,
            error: handleErrorMessage(error),
        };
    }
};
export const postUserView = async ({ productId, visitorId }) => {
    try {
        const { data } = await Axios.post(
            `https://api.aylimenu.ir/api/v1/Products/visit/${productId}`,
            {},
            {
                headers: {
                    "content-type": "Application/json",
                    deviceId: visitorId,
                    accept: "text/plain",
                },
            }
        );
        return {
            isSuccess: data.isSuccess,
            error: null,
        };
    } catch (error) {
        return {
            isSuccess: false,
            error: handleErrorMessage(error),
        };
    }
};
export const searchByProductName = async productName => {
    try {
        const request = await Axios.get(
            `api/v1/Products/search?key=${productName}`
        );
        const { data } = request;
        return {
            error: data.data.errors,
            data: data.data.data,
        };
    } catch (error) {
        return {
            error: handleErrorMessage(error),
            data: null,
        };
    }
};
export const getSiteSettings = async () => {
    try {
        const request = await Axios.get(`api/v1/SiteSettings`);
        const { data } = request;
        return {
            error: null,
            data: data.data,
        };
    } catch (error) {
        return {
            error: handleErrorMessage(error),
            data: null,
        };
    }
};
export const getPublicImageGalleries = async () => {
    try {
        const request = await Axios.get(`api/v1/PublicImageGalleries`);
        const { data } = request;
        return {
            error: null,
            data: data.data,
        };
    } catch (error) {
        return {
            error: handleErrorMessage(error),
            data: null,
        };
    }
};

export const getSingleProduct = async productId => {
    try {
        const request = await Axios.get(
            `api/v1/Products/single-product/${productId}`
        );
        const { data } = request;
        return {
            error: null,
            data: data.data,
        };
    } catch (error) {
        return {
            error: handleErrorMessage(error),
            data: null,
        };
    }
};
