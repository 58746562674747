import classes from "./Button.module.scss";
const Button = ({clicked, btnType, children}) => {
    return (
        <div className='text-center w-100'>
        
        <button
            type={btnType || "button"}
            onClick={clicked}
            className={classes.Button}
            >
            {children}
        </button>
            </div>
    );
};

export default Button;
