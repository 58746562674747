import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    items: [],
    totalQuantity: 0,
    totalCartPrice: 0,
    changed: false,
};
const cartSlice = createSlice({
    name: "cart",
    initialState: initialState,
    reducers: {
        replaceCart(state, action) {
            state.items = action.payload.items;
            state.totalQuantity = action.payload.totalQuantity;
        },
        addItemToCart(state, action) {
            const newItem = action.payload;
            const existingItem = state.items.find(
                item => item.id === newItem.id
            );
            state.totalQuantity++;
            const localPrice = state.totalCartPrice;
            const updatedTotalPrice = localPrice + newItem.price;
            state.totalCartPrice = updatedTotalPrice;
            state.changed = true;
            if (!existingItem) {
                state.items.push({
                    id: newItem.id,
                    title: newItem.title,
                    price: newItem.price,
                    image: newItem.image,
                    heart: newItem.heart,
                    quantity: 1,
                    totalPrice: newItem.price,
                });
            } else {
                existingItem.quantity++;
                existingItem.totalPrice =
                    existingItem.totalPrice + newItem.price;
            }
        },
        removeItemFromCart(state, action) {
            const itemId = action.payload.id;
            const existingItem = state.items.find(item => item.id === itemId);
            if (typeof existingItem === "undefined") return;
            state.totalQuantity--;
            const localPrice = state.totalCartPrice;
            const updatedTotalPrice = localPrice - existingItem.price;
            state.totalCartPrice = updatedTotalPrice;
            state.changed = true;
            if (existingItem.quantity === 1) {
                state.items = state.items.filter(item => item.id !== itemId);
                existingItem.quantity = 0;
            } else {
                existingItem.quantity--;
                existingItem.totalPrice =
                    existingItem?.totalPrice - existingItem?.price;
            }
        },
        clearItemsFromCart(state) {
            state.items = [];
            state.totalQuantity = 0;
            state.totalCartPrice = 0;
        },
    },
});

export const {
    removeItemFromCart,
    addItemToCart,
    replaceCart,
    clearItemsFromCart,
} = cartSlice.actions;

export default cartSlice.reducer;
