import { useState, useEffect } from "react";
import ConfigHead from "../../components/ConfigHead/ConfigHead";
import { getSiteSettings, getPublicImageGalleries } from "../../allRequest";
import Error from "../../components/Error/Error";
import Spinner from "../../components/Spinner/Spinner";
import Layout from "../../components/Layout/Layout";
import BirthDay from "../../components/BirthDay/BirthDay";
import { useLocation } from "react-router";

export default function BirthDayPage() {
    const [loading, setLoading] = useState(true);
    const [birtDayInfo, setBirtDayInfo] = useState(null);
    const [imageGallery, setImageGallery] = useState(null);
    const [error, setError] = useState(null);
    const [openError, setOpenError] = useState(true);
    const {pathname}= useLocation();

    useEffect(() => {
        setError(null);
        const fetchBirtDayInfo = async () => {
            const birtDayInfo = await getSiteSettings();
            const imageGallery = await getPublicImageGalleries();
            return { birtDayInfo, imageGallery };
        };
        if (pathname) {
            fetchBirtDayInfo().then(data => {
                if (data) {
                    setLoading(false);
                    if (data.birtDayInfo.error || data.imageGallery.error) {
                        setError(
                            data.birtDayInfo.error || data.imageGallery.error
                        );
                    } else {
                        setBirtDayInfo(data?.birtDayInfo.data);
                        setImageGallery(data?.imageGallery.data);
                    }
                }
            });
        }
    }, []);
    return (
        <Layout hasMenu={false}>
            <ConfigHead
                title=" منو آیلی | شرایط رزرو تولد"
                description="Generated by create next app"
            />
            {loading && <Spinner />}
            {error && !loading && (
                <Error
                    myError={error}
                    setOpenError={setOpenError}
                    openError={openError}
                />
            )}
            {birtDayInfo && (
                <BirthDay
                    information={{
                        birtDayInfo: birtDayInfo.birthdayOrderText,
                        imageGallery,
                    }}
                />
            )}
        </Layout>
    );
}
