import classNames from "classnames";
import classes from "./Menubar.module.scss";
import Selectbar from "../container/Selectbar/Selectbar";
import Category from "../container/Category/Category";
import MenuHeader from "../container/MenuHeader/MenuHeader";
import { BsChevronDoubleDown } from "react-icons/bs";
import { Swiper, SwiperSlide } from "swiper/react";
import { EffectCoverflow, Pagination } from "swiper";
import { Fragment, useRef, useEffect } from "react";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";
import { useNavigate, useParams } from "react-router";
import { useDispatch } from "react-redux";
import { setShowMenubar } from "src/store/ui-slice";

const Menubar = ({
    showMenubar,
    setChangedPage,
    changedPage,
    categoryPrimary,
    productCategories,
    defaultPrimary,
    realIndex,
}) => {
    const sliderRef = useRef(null);
    useEffect(() => {
        sliderRef.current.swiper.slideTo(realIndex);
    }, [realIndex]);
    const { categoryId, primaryId } = useParams();
    const navigate = useNavigate();
    const dispath = useDispatch();

    // const changeSlideHandler = event => {
    //     if ((categoryId, primaryId)) {
    //         const foundCategory = productCategories[event.activeIndex].slug;
    //         navigate(`/${primaryId}/${foundCategory}`);
    //     }
    // };
    return (
        <Fragment>
            <div
                className={classNames({
                    "col-12": true,
                    [classes.Menubar]: showMenubar,
                    [classes.smallMenubar]: !showMenubar,
                })}
            >
                <div className={classes.topSection}>
                    <div
                        className={classNames({
                            [classes.LogoContainer]: true,
                            "d-none d-xl-flex": true,
                        })}
                    >
                        <div className={classes.Logo}>
                            <div className={classes.Image} />
                        </div>
                    </div>
                    <div className="center color-white w-100 text-center d-none d-xl-inline-block">
                        <h4>دسته بندی ها :</h4>
                        <Selectbar
                            categoryPrimary={categoryPrimary}
                            defaultPrimary={defaultPrimary}
                            productCategories={productCategories}
                        />
                    </div>
                </div>
                <div className={classes.scroller}>
                    {productCategories.map((category, index) => (
                        <Category
                            name={category?.title}
                            image={category?.image}
                            key={category.id ?? index}
                            id={category.id}
                            link={`${category?.parent?.slug}/${category?.slug}`}
                        />
                    ))}
                </div>

                <div className="col-12 d-flex d-xl-none">
                    <MenuHeader
                        categoryPrimary={categoryPrimary}
                        defaultPrimary={defaultPrimary}
                    />
                </div>

                <div
                    className={classNames({
                        "d-xl-none d-inline mt-3 col-12 col-sm-8 col-md-7 col-lg-6": true,
                        [classes.showMenubar]: showMenubar,
                        [classes.hideMenubar]: !showMenubar,
                    })}
                >
                    <Swiper
                        effect={"coverflow"}
                        centeredSlides={true}
                        slidesPerView={"auto"}
                        slideToClickedSlide={true}
                        initialSlide={realIndex}
                        ref={sliderRef}
                        spaceBetween={3}
                        loop={false}
                        allowTouchMove={true}
                        // onSlideChange={changeSlideHandler}
                        speed={400}
                        grabCursor={true}
                        threshold={1}
                        coverflowEffect={{
                            rotate: 50,
                            stretch: 0,
                            depth: 100,
                            modifier: 1,
                            slideShadows: true,
                        }}
                        pagination={false}
                        modules={[EffectCoverflow, Pagination]}
                        className={classes.swiper}
                        style={
                            showMenubar
                                ? { display: "flex" }
                                : { display: "none" }
                        }
                    >
                        {productCategories.map((category, index) => (
                            <SwiperSlide key={category.id ?? index}>
                                <Category
                                    name={category?.title}
                                    image={category?.image}
                                    id={category.id}
                                    key={category.id ?? index}
                                    showMenubar={showMenubar}
                                    changedPage={changedPage}
                                    productCategories={productCategories}
                                    parentId={category?.slug}
                                    link={`${category?.parent?.slug}/${category?.slug}`}
                                />
                            </SwiperSlide>
                        ))}
                    </Swiper>
                </div>
            </div>
            <div
                className={classNames({
                    [classes.bottomIcon]: true,
                    "d-xl-none ": true,
                })}
                onClick={() => {
                    dispath(setShowMenubar(!showMenubar));
                    setChangedPage(true);
                }}
            >
                <span>
                    <BsChevronDoubleDown />
                </span>
            </div>
        </Fragment>
    );
};

export default Menubar;
