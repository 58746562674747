import "./styles.css";
const Icon = ({ icon }) => {
    const Icons = [
        "icon icon-cup",
        "icon icon-cup-1",
        "icon icon-dish",
        "icon icon-coffee-cup-1",
        "icon icon-coffee-beans-2",
        "icon icon-cold-drink-1",
        "icon icon-pizza-1",
        "icon icon-nachos",
        "icon icon-dish-1",
        "icon icon-pack-fast-food",
        "icon icon-pack-fast-food-1",
        "icon icon-french-press",
        "icon icon-cup-2",
        "icon icon-fried-chicken",
        "icon icon-cheese-1",
        "icon icon-fried-chicken-2",
        "icon icon-pizza-2",
        "icon icon-fried-chicken-3",
        "icon icon-cold-drink-2",
        "icon icon-tea",
        "icon icon-cold-drink-3",
        "icon icon-pizza-3",
        "icon icon-hot-dog",
        "icon icon-mac",
        "icon icon-milk",
        "icon icon-coffee-beans-3",
        "icon icon-pizza-4",
        "icon icon-meat",
        "icon icon-kebab",
        "icon icon-pizza-5",
        "icon icon-coffee-1",
        "icon icon-fried-chicken-4",
        "icon icon-french-fries",
        "icon icon-taco",
        "icon icon-burger",
        "icon icon-burger-1",
        "icon icon-hot-dog-1",
        "icon icon-cake",
        "icon icon-coffee",
        "icon icon-coffee-2",
        "icon icon-coffee-beans-4",
        "icon icon-mockapat",
        "icon icon-kebab-1",
        "icon icon-coffee-cup",
        "icon icon-kebab-2",
        "icon icon-kebab-3",
    ];
    const findIcon = Icons.find(i => i === icon);
    return (
        <>
            <span
                className={`${findIcon}`}
                style={{
                    width: "20px",
                    height: "20px",
                    fontSize: "25px",
                    margin: "0 0 0 5px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                }}
            ></span>
        </>
    );
};

export default Icon;
