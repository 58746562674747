import { createSlice } from "@reduxjs/toolkit";

const initialCartState = {
    cartIsVisible: false,
    imageContent: null,
    imageModal: false,
    showMenubar: true,
};
const uiSlice = createSlice({
    name: "ui",
    initialState: initialCartState,
    reducers: {
        toggle(state) {
            state.cartIsVisible = !state.cartIsVisible;
        },
        showImageModal(state) {
            state.imageModal = !state.imageModal;
        },
        getIamgeModal(state, action) {
            state.imageContent = action.payload;
        },
        setShowMenubar(state, action) {
            state.showMenubar = action.payload;
        },
    },
});
export const { toggle, showImageModal, getIamgeModal, setShowMenubar } =
    uiSlice.actions;
export default uiSlice.reducer;
